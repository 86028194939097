import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./auth.provider";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const { token } = useContext(AuthContext);
  if (!token) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
};

export default AuthWrapper;
