export default function EmptyOrderList() {
  return (
    <div className="divide-y divide-gray-200 divide-solid">
      <div
        className="py-4 
        text-gray-500 font-semibold text-md border-b border-gray-200"
      >
        No active orders
      </div>
    </div>
  );
}
