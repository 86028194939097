import { ReactNode, createContext, useState } from "react";

interface Props {
  children: ReactNode;
}

interface SearchContextProps {
  search: string;
  setSearch: (search: string) => void;
}

export const SearchContext = createContext<SearchContextProps>({
  search: "",
  setSearch: () => {},
});

export default function SearchProvider({ children }: Props) {
  const [search, setSearch] = useState<string>("");

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
}
