import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import { useContext } from "react";
import EmptyOrderDetails from "../components/empty-order-details";
import Loading from "../components/loading";
import { SelectedOrderContext } from "../providers/selected-order.provider";
import { useOrder } from "../services/orders.service";
import { OrderStatus } from "../types";
import OrderAction from "./order-actions";

export default function OrderDetails() {
  const { selectedOrder } = useContext(SelectedOrderContext);
  const orderId = selectedOrder?.id;
  const { order, loading, error } = useOrder(orderId);
  if (loading) return <Loading />;
  if (!orderId || !order) return <EmptyOrderDetails />;
  if (error) return <div className="p-4">Error: {error.message}</div>;

  const completed = order.orderStates.some(
    ({ status }) => status === OrderStatus.COMPLETED
  );
  console.log("createdAt", order?.createdAt);
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 pt-8 pb-4 sm:px-6 lg:px-8 lg:pb-12">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="max-w-xl">
            <div className="flex items-center gap-2">
              <h1 className="text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Order ID #{order.id}
              </h1>
              {completed ? (
                <CheckCircleIcon className="h-6 w-6 text-green-400" />
              ) : (
                <ClipboardDocumentCheckIcon className="h-7 w-7 text-indigo-400" />
              )}
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Order placed on{" "}
              {moment
                .utc(order.createdAt)
                .tz("America/Regina")
                .format("MMM D, YYYY h:mm A")}
            </p>
          </div>
          <div className="flex-grow lg:mt-0 mt-4" />
          <OrderAction order={order} />
        </div>

        <div className="mt-8">
          <div className="space-y-2">
            <table className="mt-2 w-full text-gray-500 sm:mt-6">
              <caption className="sr-only">Products</caption>
              <thead className="sr-only text-left text-sm text-gray-500 sm:not-sr-only">
                <tr>
                  <th
                    scope="col"
                    className="py-3 pr-8 font-normal sm:w-2/5 lg:w-1/3"
                  >
                    Product
                  </th>
                  <th
                    scope="col"
                    className="hidden w-1/5 py-3 pr-8 font-normal sm:table-cell"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3 pr-8 font-normal sm:table-cell"
                  >
                    Quantity
                  </th>
                  <th scope="col" className="w-0 py-3 text-right font-normal">
                    Size
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                {order.orderItems.map(({ product, quantity }) => (
                  <tr key={product.id}>
                    <td className="py-6 pr-8">
                      <div className="flex items-center">
                        <img
                          src={product.image?.replace(
                            "upload/",
                            "upload/q_60/w_400/f_auto/"
                          )}
                          alt={product.name}
                          defaultValue={product.name}
                          className="mr-6 h-16 w-16 rounded object-cover object-center"
                        />
                        <div>
                          <div className="font-medium text-gray-900">
                            {product.name}
                          </div>
                          <div className="mt-1 sm:hidden">
                            ${product.price.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="hidden py-6 pr-8 sm:table-cell">
                      ${product.price.toFixed(2)}
                    </td>
                    <td className="hidden py-6 pr-8 sm:table-cell">
                      x{quantity}
                    </td>
                    <td className="whitespace-nowrap py-6 text-right font-medium">
                      <p>{`${product.units} ${product.measurementUnit}`}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Billing */}
      <section aria-labelledby="summary-heading">
        <div className="bg-gray-100 px-4 py-6 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
          <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
            <div>
              <dt className="font-medium text-gray-900">
                Delivery information
              </dt>
              <dd className="mt-3 text-gray-500">
                <span className="block">Room Number: {order.roomNumber}</span>
                <span className="block">{order.tenant.name}</span>
              </dd>
            </div>
            <div>
              <dt className="font-medium text-gray-900">Payment information</dt>
              {order.cardDetails ? (
                <dd className="-ml-4 -mt-1 flex flex-wrap">
                  <div className="ml-4 mt-4 flex-shrink-0">
                    <svg
                      aria-hidden="true"
                      width={36}
                      height={24}
                      viewBox="0 0 36 24"
                      className="h-6 w-auto"
                    >
                      <rect width={36} height={24} rx={4} fill="#224DBA" />
                      <path
                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                        fill="#fff"
                      />
                    </svg>
                    <p className="sr-only">{order.cardDetails.brand}</p>
                  </div>
                  <div className="ml-4 mt-4">
                    <p className="text-gray-900">
                      Ending with {order.cardDetails.last4}
                    </p>
                    <p className="text-gray-600">
                      Expires
                      {` ${order.cardDetails.expMonth} / ${order.cardDetails.expYear}`}
                    </p>
                  </div>
                </dd>
              ) : (
                <dd className="mt-3 text-gray-500">
                  <span className="block">No payment information</span>
                </dd>
              )}
            </div>
          </dl>

          <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
            <div className="flex items-center justify-between pb-4">
              <dt className="text-gray-600">Subtotal</dt>
              <dd className="font-medium text-gray-900">
                {`$${order.priceTotals.subtotal.toFixed(2)}`}
              </dd>
            </div>
            <div className="flex items-center justify-between py-4">
              <dt className="text-gray-600">Tax total</dt>
              <dd className="font-medium text-gray-900">
                {`$${order.priceTotals.tax.toFixed(2)}`}
              </dd>
            </div>
            <div className="flex items-center justify-between pt-4">
              <dt className="font-bold text-gray-900">Order total</dt>
              <dd className="font-bold text-indigo-600">
                {`$${order.priceTotals.total.toFixed(2)}`}
              </dd>
            </div>
          </dl>
        </div>
      </section>
    </div>
  );
}
