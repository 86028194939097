import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AuthProvider from "./providers/auth.provider";
import GraphQLProvider from "./providers/graphql.provider";
import NetworkProvider from "./providers/network.provider";
import RouteProvider from "./providers/route.provider";
import SelectedOrderProvider from "./providers/selected-order.provider";
import TabProvider from "./providers/tab.provider";
import TenantProvider from "./providers/tenant.provider";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    cordova: any;
  }
}

window.cordova = window.cordova || false;

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <GraphQLProvider>
        <AuthProvider>
          <NetworkProvider>
            <TenantProvider>
              <TabProvider>
                <SelectedOrderProvider>
                  <RouteProvider />
                </SelectedOrderProvider>
              </TabProvider>
            </TenantProvider>
          </NetworkProvider>
        </AuthProvider>
      </GraphQLProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener("deviceready", startApp, false);
}

reportWebVitals();
