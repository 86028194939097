import Lottie from "lottie-react";
import loadingLottie from "../assets/loading.json";

export default function Loading() {
  return (
    <div className="flex flex-col items-center justify-center h-full mt-28">
      <Lottie
        loop
        animationData={loadingLottie}
        style={{ width: "100%", height: 65 }}
      />
      <div className="text-gray-500 text-md ml-2 mt-2.5">Loading...</div>
    </div>
  );
}
