import React, { createContext, useCallback, useEffect, useState } from "react";
import useTenant from "../hooks/tenant-context";
import { OrderStatus } from "../types";

interface Status {
  status: OrderStatus;
  createdAt: Date;
}

export interface OrderState {
  id: number;
  statuses: Status[];
}

interface WebSocketContextType {
  orders: OrderState[];
  connectionStatus: string;
  isConnected: boolean;
  handleRefetch: () => void;
}

export const WebSocketContext = createContext<WebSocketContextType>({
  orders: [],
  connectionStatus: "Disconnected",
  isConnected: false,
  handleRefetch: () => {},
});

const uri = "wss://globexmanagement.app";

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { tenant } = useTenant();
  const [orders, setOrders] = useState<OrderState[]>([]);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [isConnected, setIsConnected] = useState(false);

  const connectWebSocket = useCallback(() => {
    if (!tenant) return;
    const ws = new WebSocket(uri);
    const tenantId = tenant.id;
    ws.onopen = () => {
      console.log("Connected to WebSocket");
      setConnectionStatus("Connected");
      setIsConnected(true);
      ws.send(JSON.stringify({ type: "subscribe", tenantId }));
    };

    ws.onmessage = (event) => {
      console.log("Received WebSocket message:", event.data);
      try {
        const parsedData = JSON.parse(event.data);
        const newOrders = parsedData.orders || [];
        setOrders(newOrders);
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
        setOrders([]); // Set to empty array in case of error
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
      setConnectionStatus("Error - Disconnected");
      setIsConnected(false);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      setConnectionStatus("Disconnected");
      setIsConnected(false);
    };

    return ws;
  }, [tenant]);

  useEffect(() => {
    const ws = connectWebSocket();
    if (!ws) return;
    return () => {
      ws.close();
    };
  }, [connectWebSocket]);

  const handleRefetch = () => connectWebSocket();

  return (
    <WebSocketContext.Provider
      value={{ orders, connectionStatus, isConnected, handleRefetch }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
