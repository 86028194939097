export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  organization: Organization;
  tenants: Tenant[];
}

export interface Organization {
  id: number;
  name: string;
  description: string | null;
  image: string | null;
  bannerImage: string | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  Tenant: Tenant[];
}

export interface Tenant {
  id: number;
  name: string;
  slug: string;
  description: string | null;
  image: string | null;
  active: boolean;
  bannerImage: string | null;
  organization: Organization;
  organizationId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  Product: Product[];
  Category: Category[];
}

export interface Product {
  id: number;
  name: string;
  description: string | null;
  image: string | null;
  price: number;
  units: number;
  measurementUnit: string | null;
  available: boolean;
  featured: boolean;
  upsell: boolean;
  tenant: Tenant;
  tenantId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  category: Category;
}

export interface Category {
  id: number;
  name: string;
  image: string | null;
  tenant: Tenant;
  tenantId: number;
  position: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  products: Product[];
}

export interface PriceTotals {
  total: number;
  subtotal: number;
  tax: number;
  GST: number;
  PST: number;
}

export enum OrderStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  REFUNDED = "REFUNDED",
}

export interface Order {
  id: number;
  orderItems: OrderItem[];
  roomNumber: number;
  priceTotals: PriceTotals;
  tenant: Tenant;
  cardDetails: CardDetails | null;
  orderStates: OrderState[];
  createdAt: number;
}

interface OrderState {
  id: number;
  status: OrderStatus;
  createdAt: string;
}

export interface OrderItem {
  id: number;
  product: Product;
  quantity: number;
}

export interface PromoCard {
  id: number;
  name: string;
  image: string;
  link: string | null;
  available: boolean;
}

export interface CardDetails {
  brand: string;
  expYear: number;
  expMonth: number;
  last4: string;
}
