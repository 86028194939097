import { createContext, useEffect, useState } from "react";
import { Tenant } from "../types";

interface TenantContextProps {
  tenant: Tenant | null;
  updateTenant: (tenant: Tenant) => void;
}

export const TenantContext = createContext<TenantContextProps>({
  tenant: null,
  updateTenant: () => {},
} as TenantContextProps);

interface Props {
  children: React.ReactNode;
}

export default function TenantProvider({ children }: Props) {
  const getInitialTenant = () => {
    const savedTenant = localStorage.getItem("tenant");
    return savedTenant ? JSON.parse(savedTenant) : null;
  };

  const [tenant, setTenant] = useState<Tenant | null>(getInitialTenant);

  useEffect(() => {
    const currentTenant = localStorage.getItem("tenant");
    const hasTenantChanged = JSON.stringify(tenant) !== currentTenant;

    if (hasTenantChanged) {
      if (tenant) {
        localStorage.setItem("tenant", JSON.stringify(tenant));
        setTenant(tenant);
      } else {
        localStorage.removeItem("tenant");
      }
    }
  }, [tenant]);

  const updateTenant = (tenant: Tenant) => {
    setTenant(tenant);
  };

  return (
    <TenantContext.Provider value={{ tenant, updateTenant }}>
      {children}
    </TenantContext.Provider>
  );
}
