import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import useTenant from "../hooks/tenant-context";
import { TOGGLE_AVAILABLE, useTenantById } from "../services/tenant.service";
import SwitchToggle from "./toggle";
import { classNames } from "../utils/classnames";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import Loading from "./loading";

export default function TenantInfo() {
  const { tenant: tenantContext } = useTenant();
  const { tenant, loading } = useTenantById(tenantContext?.id);
  const [toggleAvailable] = useMutation(TOGGLE_AVAILABLE);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!tenant) return;
    setActive(tenant.active);
  }, [tenant]);

  if (loading) return <Loading />;
  if (!tenant || typeof tenant.id === "undefined") {
    console.error("Tenant or tenant.id is undefined");
    return <>error</>;
  }

  async function handleToggleAvailable() {
    if (!tenant) return;
    try {
      const result = await toggleAvailable({
        variables: { id: tenant.id, active: !active },
      });
      console.log("Mutation result:", result);
      setActive(!active);
    } catch (error) {
      console.error("Error in toggleAvailable:", error);
    }
  }

  return (
    <div>
      <div className="flex px-4 sm:px-0 items-center gap-4">
        {tenant.image && (
          <img
            className="h-14 w-14 rounded-md"
            src={tenant.image}
            alt={tenant.name}
          />
        )}
        <div>
          <h1 className="text-2xl font-semibold" id="section-1-title">
            Settings
          </h1>
          <p className="mt-1 max-w-2xl text-md leading-6 text-gray-500">
            {tenant.name}
          </p>
        </div>
      </div>
      <div className="mt-6 border-t border-gray-100" />

      <div className="mt-6 flex items-center gap-8">
        <SwitchToggle enabled={active} onChange={handleToggleAvailable} />
        <div>
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-medium">Order Services</h2>
            <p className="text-sm text-gray-500"></p>
            <span
              className={classNames(
                "inline-flex items-center gap-x-1.5  px-3 py-1.5 rounded-md text-xs font-medium",
                active
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              )}
            >
              {active ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <ExclamationTriangleIcon className="h-4 w-4" />
              )}
              {active ? "Active" : "Inactive"}
            </span>
          </div>

          <p className="text-sm text-gray-500 mt-1">
            Toggle the availability of order services
          </p>
        </div>
      </div>
    </div>
  );
}
