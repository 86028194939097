import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CREATE_ORDER_STATE, ORDER } from "../services/orders.service";
import { Order, OrderStatus } from "../types";
import { classNames } from "../utils/classnames";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { SelectedOrderContext } from "../providers/selected-order.provider";

interface Props {
  order: Order;
}

export default function OrderAction({ order }: Props) {
  const [createOrderState, { loading }] = useMutation(CREATE_ORDER_STATE, {
    variables: { orderId: order.id },
    refetchQueries: [ORDER],
  });
  const { setSelectedOrder } = useContext(SelectedOrderContext);
  async function handleConfirmOrder() {
    try {
      await createOrderState({
        variables: {
          status: OrderStatus.CONFIRMED,
          timestamp: new Date().toISOString(),
        },
      });
    } catch {
      alert("Error confirming order");
    }
  }
  async function handleCompleteOrder() {
    try {
      await createOrderState({
        variables: {
          status: OrderStatus.COMPLETED,
          timestamp: new Date().toISOString(),
        },
      });
      setSelectedOrder(null);
    } catch {
      alert("Error completing order");
    }
  }
  const { status } = order.orderStates.reduce((a, b) =>
    a.createdAt > b.createdAt ? a : b
  );
  const unconfirmed = status === OrderStatus.PAID;
  const buttonText = unconfirmed ? "Confirm Order" : "Complete Order";

  if (status === OrderStatus.COMPLETED) return null;

  return (
    <button
      onClick={unconfirmed ? handleConfirmOrder : handleCompleteOrder}
      disabled={loading}
      type="button"
      className={classNames(
        "inline-flex items-center px-5 py-3 border border-transparent text-md font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        unconfirmed
          ? "bg-indigo-600 hover:bg-indigo-700"
          : "bg-green-500 hover:bg-green-600"
      )}
    >
      {loading ? "Loading..." : buttonText}
      {unconfirmed ? (
        <ClockIcon className="h-5 w-5 ml-2" />
      ) : (
        <CheckCircleIcon className="h-5 w-5 ml-2" />
      )}
    </button>
  );
}
