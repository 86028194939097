import { useContext } from "react";
import food from "../assets/food.jpg";
import { SelectedTab } from "../constants";
import { TabContext } from "../providers/tab.provider";

export default function EmptyOrderDetails() {
  const { setTab } = useContext(TabContext);
  const navToSettings = () => setTab(SelectedTab.Settings);
  const navToPreviousOrders = () => setTab(SelectedTab.PastOrders);
  return (
    <div className="flex flex-col items-center justify-center h-96 p-12">
      <img src={food} alt="food" className="w-30 h-44 ml-4" />
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900">
        No order selected
      </h1>
      <p className="mt-2 text-base leading-7 text-gray-600">
        Select an order from the list to see order details.
      </p>
      <div className="mt-6 flex items-center justify-center gap-x-6">
        <button
          onClick={navToPreviousOrders}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Previous orders
        </button>
        <button
          onClick={navToSettings}
          className="text-sm font-semibold text-gray-900"
        >
          Settings <span aria-hidden="true">&rarr;</span>
        </button>
      </div>
    </div>
  );
}
