import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { SearchContext } from "../providers/search.provider";
import { PRODUCTS, UPDATE_PRODUCT } from "../services/product.service";
import { Product } from "../types";
import SwitchToggle from "./toggle";

interface ProductListProps {
  products: Product[];
}

export default function ProductList({ products }: ProductListProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(15);
  const { search } = useContext(SearchContext);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [PRODUCTS],
  });
  const [productIdLoading, setProductIdLoading] = useState<number | null>(null);
  useEffect(() => {
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [search, products]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  async function handleToggleProduct(
    id: number,
    available?: boolean,
    featured?: boolean,
    upsell?: boolean
  ) {
    try {
      setProductIdLoading(id);
      await updateProduct({
        variables: {
          id,
          input: {
            available,
            featured,
            upsell,
          },
        },
      });
      setProductIdLoading(null);
    } catch (error) {
      console.error(error);
      setProductIdLoading(null);
    }
  }

  const toggleAvailable = (id: number, available: boolean) => {
    handleToggleProduct(id, available);
  };
  const toggleFeatured = (id: number, featured: boolean) => {
    handleToggleProduct(id, undefined, featured);
  };
  const toggleUpsell = (id: number, upsell: boolean) => {
    handleToggleProduct(id, undefined, undefined, upsell);
  };

  return (
    <div>
      <div className="overflow-auto" style={{ height: "600px" }}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Image
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Measurement
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Available
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Featured
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Upsell
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {currentProducts.map((product) => {
              const loading = productIdLoading === product.id;
              return (
                <tr key={product.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.image && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={product.image}
                        alt={product.name}
                      />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    ${product.price}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {`${product.units} ${product.measurementUnit} `}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <SwitchToggle
                      enabled={product.available}
                      loading={loading}
                      onChange={() =>
                        toggleAvailable(product.id, !product.available)
                      }
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <SwitchToggle
                      enabled={product.featured}
                      loading={loading}
                      onChange={() =>
                        toggleFeatured(product.id, !product.featured)
                      }
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <SwitchToggle
                      enabled={product.upsell}
                      loading={loading}
                      onChange={() => toggleUpsell(product.id, !product.upsell)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-12 border-t-2">
        {Array.from(
          { length: Math.ceil(filteredProducts.length / productsPerPage) },
          (_, i) => i + 1
        ).map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-5 rounded-md py-3 mt-3 mx-1 ${
              currentPage === number
                ? "bg-indigo-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
}
