export const TOKEN = "token";
export const USER = "user";
export const PLACEHOLDER_IMAGE =
  "https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg";

export enum SelectedTab {
  ActiveOrders = "Active Orders",
  PastOrders = "Past Orders",
  Products = "Products",
  Settings = "Settings",
}
