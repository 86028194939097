import { ReactNode, createContext, useState } from "react";
import { OrderState } from "../orders/order-list";

interface Props {
  children: ReactNode;
}

interface OrderContextProps {
  selectedOrder: OrderState | null;
  setSelectedOrder: (order: OrderState | null) => void;
}

export const SelectedOrderContext = createContext<OrderContextProps>({
  selectedOrder: null,
  setSelectedOrder: () => {},
});

export default function SelectedOrderProvider({ children }: Props) {
  const [selectedOrder, setSelectedOrder] = useState<OrderState | null>(null);

  return (
    <SelectedOrderContext.Provider value={{ selectedOrder, setSelectedOrder }}>
      {children}
    </SelectedOrderContext.Provider>
  );
}
