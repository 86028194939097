import { useEffect, useState } from "react";
import TabContainer from "../components/tab-container";
import UnconfirmedOrdersModal from "../components/unconfirmed-orders-modal";
import { OrderStatus } from "../types";
import { useWebSocket } from "../utils/use-websocket";

export default function Test() {
  const { orders } = useWebSocket();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasUnconfirmedOrders = orders.some(
      (order: { statuses: any[] }) =>
        !order.statuses.some(({ status }) => status === OrderStatus.CONFIRMED)
    );

    if (hasUnconfirmedOrders) {
      setShowModal(true);
    }
  }, [orders]);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <TabContainer />
      {showModal && (
        <UnconfirmedOrdersModal
          orders={orders}
          open={showModal}
          closeModal={handleCloseModal}
        />
      )}
    </>
  );
}
