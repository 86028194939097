import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ReactNode } from "react";
import { TOKEN } from "../constants";
const PROD_URL = "https://api-2gsi4ic44q-uc.a.run.app/graphql";
// const DEV_URL = "http://localhost:8080/graphql";
const httpLink = createHttpLink({
  uri: PROD_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}` ?? undefined,
    },
  };
});

interface Props {
  children: ReactNode;
}

const APOLLO_CLIENT = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function GraphQLProvider({ children }: Props) {
  return <ApolloProvider client={APOLLO_CLIENT}>{children}</ApolloProvider>;
}
