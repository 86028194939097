import { ArrowPathIcon, WifiIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import EmptyOrderList from "../components/empty-order-list";
import { OrderStatus } from "../types";
import { classNames } from "../utils/classnames";
import { useWebSocket } from "../utils/use-websocket";
import OrderListItem from "./order-list-item";

interface Status {
  status: OrderStatus;
  createdAt: Date;
}

export interface OrderState {
  id: number;
  statuses: Status[];
}

export default function OrderList() {
  const { orders, isConnected, handleRefetch } = useWebSocket();

  const [connectionStatus, setConnectionStatus] = useState("Disconnected");

  useEffect(() => {
    if (isConnected) {
      setConnectionStatus("Connected");
    } else {
      setConnectionStatus("Disconnected");
    }
  }, [isConnected]);

  return (
    <>
      <div>
        <p className="mt-1 text-sm text-gray-500">
          Orders are updated in real time
        </p>

        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">
            <WifiIcon
              className={classNames(
                "h-6 w-6",
                isConnected ? "text-green-400" : "text-red-500"
              )}
            />
            <p className="ml-2 text-md font-medium text-gray-500">
              {connectionStatus}
            </p>
          </div>
          <button
            type="button"
            className="rounded bg-indigo-500 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={handleRefetch}
          >
            <ArrowPathIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
      <div className="mt-4 grid grid-cols-1 gap-4">
        {orders.length > 0 ? (
          orders.map((order) => <OrderListItem key={order.id} order={order} />)
        ) : (
          <EmptyOrderList />
        )}
      </div>
    </>
  );
}
