import { Menu, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useContext, useEffect, useState } from "react";
import icon from "../assets/icon.png";
import logo from "../assets/logo.png";
import Footer from "../components/footer";
import LocationDropdown from "../components/location-dropdown";
import MainTabContent from "../components/main-content";
import MobileSearch from "../components/mobile-search-bar";
import SearchBar from "../components/search-bar";
import { SelectedTab } from "../constants";
import { AuthContext } from "../providers/auth.provider";
import SearchProvider from "../providers/search.provider";
import { TabContext } from "../providers/tab.provider";
import { classNames } from "../utils/classnames";
import { useWebSocket } from "../utils/use-websocket";
import { OrderStatus } from "../types";

const navigation = [
  { name: "Active Orders", tab: SelectedTab.ActiveOrders },
  { name: "Past Orders", tab: SelectedTab.PastOrders },
  { name: "Products", tab: SelectedTab.Products },
  { name: "Settings", tab: SelectedTab.Settings },
];

export default function TabContainer() {
  const { currentTab, setTab } = useContext(TabContext);
  const { orders } = useWebSocket();
  const { user, handleLogout: action } = useContext(AuthContext);
  const userNavigation = [{ name: "Sign out", action }];
  const initials = user ? user?.firstName[0] + user?.lastName[0] : "";

  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    if (!orders || orders.length === 0) {
      setShowBadge(false);
      return;
    }

    const hasUnconfirmedOrders = orders.some(
      (order: { statuses: any[] }) =>
        !order.statuses.some(({ status }) => status === OrderStatus.CONFIRMED)
    );

    if (hasUnconfirmedOrders) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [orders]);

  return (
    <SearchProvider>
      <div className="min-h-full">
        <Popover as="header" className="bg-slate-800 pb-24">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative flex items-center justify-center py-5 lg:justify-between">
                  <div className="relative lg:static">
                    <img
                      className="h-8 w-8 lg:hidden block"
                      src={icon}
                      alt="Globex Icon"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={logo}
                      alt="Globex"
                    />
                  </div>
                  <div className="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
                    <LocationDropdown />
                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-indigo-500 text-sm ring-2 ring-indigo-600 ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                          <span className="absolute -inset-1.5" />
                          <div className="h-9 w-9 text-white rounded-full items-center flex justify-center text-md">
                            {initials.toUpperCase()}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute -right-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map(({ name, action }) => (
                            <Menu.Item key={name}>
                              {({ active }) => (
                                <div
                                  onClick={action}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {name}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  <MobileSearch />

                  {/* Menu button */}
                  <div className="absolute right-0 flex-shrink-0 lg:hidden">
                    {/* Mobile menu button */}
                    <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-transparent p-2 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden border-t border-white border-opacity-20 py-5 lg:block">
                  <div className="grid grid-cols-3 items-center gap-8">
                    <div className="col-span-2">
                      <nav className="flex space-x-4">
                        {navigation.map(({ name, tab }) => (
                          <div
                            key={name}
                            onClick={() => setTab(tab)}
                            className={classNames(
                              tab === currentTab
                                ? "bg-slate-100 text-indigo-700"
                                : "text-gray-500 hover:text-gray-700",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                          >
                            {name}
                            {showBadge && tab === SelectedTab.ActiveOrders && (
                              <span
                                className={classNames(
                                  "bg-red-500 text-white rounded-full px-2.5 py-0.5 text-xs font-medium ml-3",
                                  "inline-block"
                                )}
                              >
                                New
                              </span>
                            )}
                          </div>
                        ))}
                      </nav>
                    </div>
                    <SearchBar />
                  </div>
                </div>
              </div>

              <Transition.Root as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition"
                    >
                      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="pb-2 pt-3">
                          <div className="flex items-center justify-between px-4">
                            <div className="-mr-2">
                              <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            {navigation.map((tab) => (
                              <div
                                key={tab.name}
                                onClick={() => setTab(tab.tab)}
                                className={classNames(
                                  tab.name === currentTab
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-900 hover:bg-gray-100",
                                  "block rounded-md px-3 py-2 text-base font-medium"
                                )}
                              >
                                {tab.name}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="pb-2 pt-4">
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              <div className="h-10 w-10 rounded-full bg-indigo-500 text-white flex items-center justify-center text-md">
                                {initials.toUpperCase()}
                              </div>
                            </div>
                            <div className="ml-3 min-w-0 flex-1">
                              <div className="truncate text-base font-medium text-gray-800">
                                {user?.firstName} {user?.lastName}
                              </div>
                              <div className="truncate text-sm font-medium text-gray-500">
                                {user?.email}
                              </div>
                            </div>
                            <button
                              type="button"
                              className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <span className="absolute -inset-1.5" />
                              <BellIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>

                          <div className="pb-2 pt-400">
                            <div className="flex items-center px-5">
                              <LocationDropdown />
                            </div>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            {userNavigation.map(({ name, action }) => (
                              <div
                                key={name}
                                onClick={action}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                              >
                                {name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
            </>
          )}
        </Popover>
        <MainTabContent currentTab={currentTab} />
        <Footer />
      </div>
    </SearchProvider>
  );
}
