import moment from "moment-timezone"; // Updated import
import { useContext } from "react";
import { SelectedOrderContext } from "../providers/selected-order.provider";
import { OrderStatus } from "../types";
import { classNames } from "../utils/classnames";
import { OrderState } from "./order-list";

interface Props {
  order: OrderState;
}

export default function OrderListItem({ order }: Props) {
  const { selectedOrder, setSelectedOrder } = useContext(SelectedOrderContext);
  const isSelected = selectedOrder?.id === order.id;
  const currentStatus = order.statuses[order.statuses.length - 1];

  const confirmed = order.statuses.some(
    (status) => status.status === OrderStatus.CONFIRMED
  );
  const status = confirmed ? "Confirmed" : "Unconfirmed";
  return (
    <li
      key={order.id}
      className={classNames(
        "col-span-1 flex rounded-md shadow-sm cursor-pointer",
        isSelected
          ? confirmed
            ? "border-indigo-500 ring-4 ring-indigo-500"
            : "border-red-400 ring-4 ring-red-400"
          : "border-gray-200"
      )}
      onClick={() => setSelectedOrder(order)}
    >
      <div
        className={classNames(
          "flex w-16 h-18 flex-shrink-0 items-center justify-center rounded-l-md text-md font-bold text-white",
          confirmed ? "bg-indigo-500" : "bg-red-400 text-indigo-800"
        )}
      >
        {order.id}
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <div className="font-medium text-xl text-gray-900 hover:text-gray-600">
            Order #{order.id}
          </div>
          <div className="flex items-center gap-2">
            <p
              className={classNames(
                "text-gray-500 font-medium",
                confirmed ? "text-indigo-500" : "text-red-400"
              )}
            >
              {status}:
            </p>

            <p className="text-gray-500">
              {moment
                .utc(currentStatus.createdAt)
                .tz("America/Regina")
                .format("MMM D, YYYY h:mm A")}
            </p>
          </div>
        </div>
        {/* <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div> */}
      </div>
    </li>
  );
}
