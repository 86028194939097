import TenantInfo from "../components/tenant-info";

export default function Settings() {
  return (
    <main className="-mt-24 pb-8">
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <section aria-labelledby="section-1-title">
          <div className="overflow-hidden rounded-lg bg-white min-h-96 shadow p-4">
            <TenantInfo />
          </div>
        </section>
      </div>
    </main>
  );
}
