import { SelectedTab } from "../constants";
import ActiveOrders from "../pages/active-orders";
import PastOrders from "../pages/past-orders";
import Products from "../pages/products";
import Settings from "../pages/settings";

interface Props {
  currentTab: SelectedTab;
}

export default function MainTabContent({ currentTab }: Props) {
  switch (currentTab) {
    case SelectedTab.ActiveOrders:
      return <ActiveOrders />;
    case SelectedTab.PastOrders:
      return <PastOrders />;
    case SelectedTab.Products:
      return <Products />;
    default:
      return <Settings />;
  }
}
