import { createHashRouter, Navigate } from "react-router-dom";
import Home from "../pages/home";
import Login from "../pages/login";
import AuthWrapper from "../providers/auth-wrapper";
import { WebSocketProvider } from "../providers/websocket.provider";

const router = createHashRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <AuthWrapper>
        <WebSocketProvider>
          <Home />
        </WebSocketProvider>
      </AuthWrapper>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

export default router;
