import { gql, useQuery } from "@apollo/client";
import { Tenant } from "../types";

export const TENANTS = gql`
  query tenants {
    tenants {
      id
      name
      image
      slug
    }
  }
`;

export function useTenants() {
  const { data, loading, error, refetch } = useQuery<{ tenants: Tenant[] }>(
    TENANTS
  );
  const tenants: Tenant[] | undefined = data?.tenants;
  return { tenants, loading, error, refetch };
}

export const TENANT = gql`
  query getTenantById($id: Int!) {
    getTenantById(id: $id) {
      id
      name
      image
      slug
      description
      active
    }
  }
`;

export function useTenantById(id?: number) {
  const { data, loading, error, refetch } = useQuery<{ getTenantById: Tenant }>(
    TENANT,
    {
      variables: { id },
      skip: !id,
    }
  );
  const tenant: Tenant | undefined = data?.getTenantById;
  return { tenant, loading, error, refetch };
}

export const CREATE_TENANT = gql`
  mutation createTenant($input: TenantInput!) {
    createTenant(input: $input) {
      id
      name
      image
      slug
    }
  }
`;

export const TOGGLE_AVAILABLE = gql`
  mutation toggleAvailability($id: Int!, $active: Boolean!) {
    toggleAvailability(id: $id, active: $active) {
      id
    }
  }
`;
