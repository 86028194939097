import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        id
        firstName
        lastName
        fullname
        email
        organization {
          id
          name
          image
        }
        tenants {
          id
          name
          description
          image
        }
      }
    }
  }
`;
