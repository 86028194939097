import { useContext } from "react";
import ProductList from "../components/product-list";
import useTenant from "../hooks/tenant-context";
import { SearchContext } from "../providers/search.provider";
import { useProducts } from "../services/product.service";
import Loading from "../components/loading";

export default function Products() {
  const { tenant } = useTenant();
  const { search } = useContext(SearchContext);
  const { products, loading } = useProducts(tenant?.id);
  if (loading) return <Loading />;
  if (!products) return null;

  const filteredProducts = products.filter(({ name }) => {
    return name.toLowerCase().includes(search.toLowerCase());
  });
  return (
    <main className="-mt-24 pb-8">
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <section aria-labelledby="section-1-title">
          <div className="overflow-hidden rounded-lg bg-white min-h-96 shadow p-4">
            <ProductList products={filteredProducts} />
          </div>
        </section>
      </div>
    </main>
  );
}
