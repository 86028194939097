import { ReactNode, createContext, useState } from "react";
import { SelectedTab } from "../constants";

interface Props {
  children: ReactNode;
}

interface TabContextProps {
  currentTab: SelectedTab;
  setTab: (tab: SelectedTab) => void;
}

export const TabContext = createContext<TabContextProps>({
  currentTab: SelectedTab.ActiveOrders,
  setTab: () => {},
});

export default function TabProvider({ children }: Props) {
  const [currentTab, setTab] = useState<SelectedTab>(SelectedTab.ActiveOrders);

  return (
    <TabContext.Provider value={{ currentTab, setTab }}>
      {children}
    </TabContext.Provider>
  );
}
