import { Dialog, Transition } from "@headlessui/react";
import Lottie from "lottie-react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import alertLottie from "../assets/alert.json";
import { OrderState } from "../orders/order-list";
// @ts-ignore
import alertSound from "../assets/alert.mp3";
import { SelectedTab } from "../constants";
import OrderListItem from "../orders/order-list-item";
import { SelectedOrderContext } from "../providers/selected-order.provider";
import { TabContext } from "../providers/tab.provider";

interface Props {
  orders: OrderState[];
  open: boolean;
  closeModal: () => void;
}

export default function UnconfirmedOrdersModal({
  open,
  closeModal,
  orders,
}: Props) {
  const audioRef = useRef(new Audio(alertSound));
  const [isPlaying, setIsPlaying] = useState(false);
  const { setTab } = useContext(TabContext);
  const { setSelectedOrder } = useContext(SelectedOrderContext);
  const unconfirmedOrders = orders.filter(
    ({ statuses }) => statuses.length === 1
  );

  useEffect(() => {
    audioRef.current.loop = true;
  }, []);

  useEffect(() => {
    if (unconfirmedOrders.length > 0 && open) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [unconfirmedOrders.length, open]);

  useEffect(() => {
    try {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    } catch (error) {
      console.error("Error playing audio", error);
    }
  }, [isPlaying]);

  function handleClose() {
    closeModal();
    audioRef.current.pause();
  }

  function handleOnClick(order: OrderState) {
    setTab(SelectedTab.ActiveOrders);
    setSelectedOrder(order);
    handleClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gray-100">
                    <Lottie animationData={alertLottie} loop />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      ({unconfirmedOrders.length}) NEW ORDERS RECEIVED!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        These orders are not yet confirmed:
                      </p>
                    </div>
                    <div className="flex flex-col mt-5 gap-2 h-96 overflow-scroll">
                      {unconfirmedOrders.map((order) => (
                        <div
                          key={order.id}
                          onClick={() => handleOnClick(order)}
                        >
                          <OrderListItem key={order.id} order={order} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-12 border-t-2 border-gray-200" />

                <div className="mt-4 sm:mt-4 sm:flex sm:flex-row-reverse items-center justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-gray-200 px-5 py-4 text-sm font-semibold text-gray-900 shadow-sm hover:bg-indigo-500 sm:w-auto sm:px-6 sm:py-3"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
