import React, { createContext, useEffect, useState } from "react";
import { TOKEN, USER } from "../constants";
import { User } from "../types";

type AuthContextType = {
  token: string | null;
  user: User | null;
  updateAuthState: (updates: Partial<AuthContextType>) => void;
  handleLogout: () => void;
};

const initialAuthState: AuthContextType = {
  token: localStorage.getItem(TOKEN) ?? null,
  user: JSON.parse(localStorage.getItem(USER) || "null"),
  updateAuthState: () => {
    throw new Error("Auth state updater must be overridden");
  },
  handleLogout: () => {
    throw new Error("Logout must be overridden");
  },
};

export const AuthContext = createContext<AuthContextType>(initialAuthState);

interface Props {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: Props) {
  const [authState, setAuthState] = useState<AuthContextType>(initialAuthState);

  useEffect(() => {
    localStorage.setItem(TOKEN, authState.token ?? "");
    localStorage.setItem(USER, JSON.stringify(authState.user));
  }, [authState]);

  const updateAuthState = (updates: Partial<AuthContextType>) => {
    setAuthState((currentState) => ({ ...currentState, ...updates }));
  };

  function handleLogout() {
    updateAuthState({ token: null, user: null });
  }

  return (
    <AuthContext.Provider
      value={{ ...authState, updateAuthState, handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
