import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { TOKEN } from "../constants";
import { AuthContext } from "../providers/auth.provider";
import { TenantContext } from "../providers/tenant.provider";
import { LOGIN } from "../services/auth.service";
import { User } from "../types";

interface LoginResponse {
  login: {
    token: string;
    user: User;
  };
}

export default function Login() {
  const { updateAuthState } = useContext(AuthContext);
  const { updateTenant } = useContext(TenantContext);
  const navigate = useNavigate();
  const [loginMutation, { loading }] = useMutation<LoginResponse>(LOGIN, {
    onCompleted: (data) => {
      const token = data.login.token;
      const user = data.login.user;
      updateAuthState({ token, user });
      localStorage.setItem(TOKEN, token);
      if (user.tenants.length > 0) {
        updateTenant(user.tenants[0]);
        navigate("/");
      } else {
        navigate("/select-location");
      }
    },
    onError: ({ message }) => {
      alert(message);
    },
  });

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email: string | null = data.get("email") as string;
    const password: string | null = data.get("password") as string;
    if (!email || email === "") {
      alert("Email is required");
      return;
    }
    await loginMutation({
      variables: {
        input: {
          email: email.toLocaleLowerCase(),
          password,
        },
      },
    });
  }

  return (
    <div className="h-screen bg-slate-900">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white/5 p-6 rounded-md shadow-lg">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-12">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Globex" />
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  disabled={loading}
                  className="block w-full rounded-md border-0 bg-white/5 p-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 text-md sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  disabled={loading}
                  className="block w-full rounded-md border-0 bg-white/5 p-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 text-md sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-3 text-md font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {loading ? "Loading..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
