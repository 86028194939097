import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { Fragment, useContext } from "react";
import { TenantContext } from "../providers/tenant.provider";
import { useTenants } from "../services/tenant.service";
import { Tenant } from "../types";
import { SelectedOrderContext } from "../providers/selected-order.provider";

export default function LocationDropdown() {
  const { tenants, loading } = useTenants();
  const { tenant, updateTenant } = useContext(TenantContext);
  const { setSelectedOrder } = useContext(SelectedOrderContext);
  if (loading) return <div>Loading...</div>;
  if (!tenants) return <div>No tenants</div>;
  if (!tenant) updateTenant(tenants[0]);
  function changeTenant(tenant: Tenant) {
    updateTenant(tenant);
    setSelectedOrder(null);
  }
  const isSelected = ({ id }: Tenant) => tenant?.id === id;
  const image = tenant?.image ?? null;
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex max-w-52 justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 truncate">
            {tenant ? tenant.name : "Location"}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {tenants.map((item) => {
                if (isSelected(item) && tenants.length > 1) return null;
                return (
                  <Menu.Item key={item.id}>
                    <div
                      onClick={() => changeTenant(item)}
                      className="block px-4 py-2 text-sm items-center justify-between text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer font-md"
                    >
                      <span className="flex items-center">
                        {image ? (
                          <img
                            src={image}
                            alt="location"
                            className="mr-2 h-5 w-5 rounded-sm"
                          />
                        ) : (
                          <BuildingOffice2Icon
                            className="mr-2 h-5 w-5 text-gray-600"
                            aria-hidden="true"
                          />
                        )}
                        <p
                          className="font-medium
                          text-gray-900"
                        >
                          {item.name}
                        </p>
                      </span>
                    </div>
                  </Menu.Item>
                );
              })}
              {tenants.length > 1 && (
                <Menu.Item>
                  <div className="border-2 border-gray-200" />
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
