import OrderDetails from "../orders/order-details";
import OrderList from "../orders/order-list";

export default function ActiveOrders() {
  return (
    <main className="-mt-24 pb-8">
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <section aria-labelledby="section-1-title">
              <div className="overflow-hidden rounded-lg bg-white min-h-96 shadow">
                <OrderDetails />
              </div>
            </section>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <section aria-labelledby="section-2-title">
              <div className="overflow-scroll rounded-lg bg-white min-h-96 shadow">
                <div className="p-6">
                  <OrderList />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}
