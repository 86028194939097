import { gql, useQuery } from "@apollo/client";
import { Product } from "../types";

export const PRODUCTS = gql`
  query products($tenantId: Int!) {
    products(tenantId: $tenantId) {
      id
      name
      price
      image
      measurementUnit
      tenantId
      units
      featured
      upsell
      description
      available
      category {
        id
        name
      }
    }
  }
`;

export function useProducts(tenantId?: number) {
  const { data, loading, error, refetch } = useQuery<{ products: Product[] }>(
    PRODUCTS,
    { variables: { tenantId }, skip: !tenantId }
  );
  const products: Product[] | undefined = data?.products;
  return { products, loading, error, refetch };
}

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $input: ProductUpdateInput!) {
    updateProduct(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($tenantId: Int!, $input: ProductInput!) {
    createProduct(tenantId: $tenantId, input: $input) {
      id
    }
  }
`;

export const TOGGLE_PRODUCT = gql`
  mutation toggleProductAvailability($id: Int!) {
    toggleProductAvailability(id: $id) {
      id
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: Int!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;
