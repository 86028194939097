import { gql, useQuery } from "@apollo/client";
import { Order } from "../types";

export const ORDERS = gql`
  query tenantOrders($id: Int!, $from: DateTime, $to: DateTime) {
    tenantOrders(id: $id, from: $from, to: $to) {
      id
      status
      priceTotals {
        total
      }
      orderItems {
        id
      }
      roomNumber
      createdAt
    }
  }
`;

export function useOrders(tenantId?: number, fromDate?: Date, toDate?: Date) {
  const { data, loading, error, refetch } = useQuery<{
    tenantOrders: Order[];
  }>(ORDERS, {
    variables: {
      id: tenantId,
      from: fromDate,
      to: toDate,
    },
    skip: !tenantId,
  });
  const orders: Order[] | undefined = data?.tenantOrders;
  return { orders, loading, error, refetch };
}

export const ORDER = gql`
  query getOrderById($id: Int!) {
    getOrderById(id: $id) {
      id
      status
      priceTotals {
        tax
        total
        subtotal
      }
      tenant {
        id
        name
      }
      cardDetails {
        brand
        expYear
        expMonth
        last4
      }
      orderStates {
        id
        status
        createdAt
      }
      orderItems {
        quantity
        product {
          id
          name
          price
          image
          units
          measurementUnit
        }
      }
      roomNumber
      createdAt
    }
  }
`;

export function useOrder(orderId?: number) {
  const { data, loading, error, refetch } = useQuery<{
    getOrderById: Order;
  }>(ORDER, {
    variables: { id: orderId },
    skip: !orderId,
    fetchPolicy: "cache-first",
  });
  const order: Order | undefined = data?.getOrderById;
  return { order, loading, error, refetch };
}

export const CREATE_ORDER_STATE = gql`
  mutation createOrderState(
    $orderId: Int!
    $status: String!
    $timestamp: DateTime!
  ) {
    createOrderState(
      orderId: $orderId
      status: $status
      timestamp: $timestamp
    ) {
      status
      createdAt
    }
  }
`;

export const CONFIRM_MULTIPLE_ORDERS = gql`
  mutation confirmMultipleOrders($orderIds: [Int!]!, $timestamp: DateTime!) {
    confirmMultipleOrders(orderIds: $orderIds, timestamp: $timestamp)
  }
`;
