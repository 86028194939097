import { ArrowPathIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone"; // Updated import
import { useContext, useEffect, useState } from "react";
import useTenant from "../hooks/tenant-context";
import { useOrders } from "../services/orders.service";
import { Order } from "../types";
import { classNames } from "../utils/classnames";
import { SearchContext } from "../providers/search.provider";
import Loading from "../components/loading";

interface Props {
  selected: Order | null;
  setSelected: (order: Order | null) => void;
}

export default function PastOrderList({ selected, setSelected }: Props) {
  const { tenant } = useTenant();
  const { search } = useContext(SearchContext);

  const [fromDate, setFromDate] = useState<string>(
    new Date(Date.now() - 36 * 60 * 60 * 1000).toISOString().split("T")[0] // 36 hours ago
  );
  const [toDate, setToDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  const { orders, loading, refetch } = useOrders(
    tenant?.id,
    new Date(fromDate),
    new Date(toDate)
  );

  useEffect(() => {
    if (orders && orders.length > 0) {
      const firstOrder = orders[0];
      setSelected(firstOrder);
    } else {
      setSelected(null);
    }
  }, [orders, setSelected]);

  if (loading) return <Loading />;
  if (!orders) return <p>No orders found</p>;

  const filteredOrders = orders.filter(({ id }) => {
    return id.toString().includes(search);
  });

  return (
    <>
      <div className="flex items-center justify-between mt-2">
        <p className="text-md font-medium text-gray-500">Previous Orders</p>
        <button
          type="button"
          onClick={refetch}
          className="rounded bg-indigo-500 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 flex items-center justify-center"
        >
          <ArrowPathIcon className="h-4 w-4" />
        </button>
      </div>

      {/* Date Range Selector */}
      <div className="mt-4 flex justify-between items-center">
        <input
          type="date"
          className="rounded shadow-sm border-2 border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 p-2 font-medium"
          value={fromDate}
          onChange={({ target }) => setFromDate(target.value)}
        />
        <span className="mx-2">&rarr;</span>
        <input
          type="date"
          className="rounded shadow-sm border-2 border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 p-2 font-medium"
          value={toDate}
          onChange={({ target }) => setToDate(target.value)}
        />
      </div>

      <div className="mt-4 grid grid-cols-1 gap-2.5">
        {filteredOrders && filteredOrders.length > 0 ? (
          filteredOrders.map((order) => (
            <div
              onClick={() => setSelected(order)}
              key={order.id}
              className={classNames(
                selected?.id === order.id
                  ? "border-indigo-500 ring-2 ring-indigo-500"
                  : "border-gray-200",
                "col-span-1 flex rounded-md shadow-sm"
              )}
            >
              <div
                className={classNames(
                  selected?.id === order.id ? "bg-indigo-400" : "bg-indigo-100",
                  "flex w-8 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              />
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="text-lg font-medium text-gray-900 hover:text-gray-600">
                    Order #{order.id}
                  </div>
                  <p className="text-gray-500 text-md">
                    {moment
                      .utc(order.createdAt)
                      .tz("America/Regina")
                      .format("MMM D, YYYY h:mm A")}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-4">
            <p className="text-gray-500">No orders found</p>
          </div>
        )}
      </div>
    </>
  );
}
